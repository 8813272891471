<script setup lang="ts">
const { breadcrumbs } = useBreadcrumbs();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const route = useRoute();
</script>
<template>
  <nav
    v-if="route.params.all != ''"
    class="lg:flex my-8 custom-container"
    aria-label="Breadcrumbs"
  >
    <ol class="pl-0 inline-flex flex-wrap gap-y-1 items-center gap-x-3">
      <li class="inline-flex items-center">
        <NuxtLink
          :to="formatLink(`/`)"
          class="inline-flex items-center text-xs md:text-sm font-medium c-scheppach-neutral-400 dark:text-secondary-400"
        >
          <div
            class="w-4 h-4 i-sl-house-chimney-1 mr-2 c-scheppach-neutral-400"
            :aria-label="$t('home')"
          />
        </NuxtLink>
        <div class="ml-1 w-3 h-3 i-sl-arrow-right-1 c-scheppach-neutral-400" />
      </li>
      <li
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="breadcrumb.path"
        class="inline-flex items-center"
      >
        <NuxtLink
          v-if="breadcrumb.path"
          :to="formatLink(breadcrumb.path)"
          class="inline-flex items-center text-xs md:text-sm font-medium dark:text-secondary-400"
          :class="{
            'c-scheppach-primary-500': index === breadcrumbs.length - 1,
            'c-scheppach-neutral-700': index < breadcrumbs.length - 1,
          }"
        >
          {{ breadcrumb.name }}
        </NuxtLink>
        <span
          v-else
          class="inline-flex items-center text-xs md:text-sm font-medium dark:text-secondary-400 dark:hover:text-white"
          :class="{
            'c-scheppach-primary-500': index === breadcrumbs.length - 1,
            'c-scheppach-neutral-400': index < breadcrumbs.length - 1,
          }"
        >
          {{ breadcrumb.name }}
        </span>
        <div
          v-if="index < breadcrumbs.length - 1"
          class="ml-3 w-3 h-3 i-sl-arrow-right-1 c-scheppach-neutral-400"
        />
      </li>
    </ol>
  </nav>
</template>
